<template>
  <q-td
    v-if="isEvalItemExists"
    class="text-center th-td-style td-style"
    :rowspan="1" :colspan="colspan">
    <div class="container-area">
      <div class="header-area">
        {{result.riskFactors}}
        <i v-if="required" class="material-icons labelfrontIcon text-requiredColor">check</i>
      </div>
      <div class="value-area">
        <template v-if="type==='number'">
          <c-number-column
            :required="required"
            :editable="editable"
            :col="{ name: 'riskFactorsResult' }"
            :props="{ row: result }"
            dense
            type="number"
            class="tableHeaderText"
            v-model="result.riskFactorsResult"
            @datachange="datachange"
          />
        </template>
        <template v-else-if="type==='radio'">
          <c-radio
            :required="required"
            :editable="editable"
            :codeGroupCd="codeGroupCd"
            itemText="codeName"
            itemValue="code"
            label=""
            v-model="result.riskFactorsResult"
            @datachange="datachange"
          />
        </template>
        <template v-else-if="type==='check'">
          <q-checkbox
            :required="required"
            :disable="!editable"
            :true-value="trueValue ? trueValue : 'Y'"
            :false-value="falseValue ? falseValue : 'N'"
            :rules="[checkboxRule]"
            dense
            color="orange-custom"
            class="tableCheckBox"
            v-model="result.riskFactorsResult"
            @datachange="datachange"
          />
        </template>
        <template v-else-if="type==='text'">
          <span class="text-left">
            <c-text-column
              :required="required"
              :editable="editable"
              :col="{ name: 'riskFactorsResult', align: 'left', }"
              :props="{ row: result }"
              @datachange="datachange"
            />
          </span>
        </template>
      </div>
    </div>
  </q-td>
</template>

<script>
export default {
  name: 'brainCardiovascularInfoItemTd',
  props: {
    result: {
      type: Object,
      default: () => ({
        heaBrainCardiovascularEvalResultId: '',  // 뇌심혈관계 평가결과 일련번호
        heaBrainCardiovascularSurveyId: '',  // 뇌심혈관계 일련번호
        heaBrainCardiovascularEvalItemId: '',  // 뇌심혈관계 평가항목 일련번호
        workSuitEvalItemClassCd: '',  // 위험요인 구분코드
        workSuitEvalItemClassName: '',  // 위험요인 구분코드
        riskFactors: '',  // 위험요인
        riskFactorsResult: '',  // 위험요인 평가결과(현재상태)
        riskFactorsGoalResult: '',  // 목표상태
        healthConditionCd: '',  // 건강신호등
        regUserId: '',  //
        editFlag: ''
      }),
    },
    colspan: {
      type: Number,
      default: () => 1,
    },
    codeGroupCd: {
      type: String,
      default: () => '',
    },
    type: {
      type: String,
      default: () => '',
    },
    trueValue: {
      type: String,
      default: () => '',
    },
    falseValue: {
      type: String,
      default: () => '',
    },
    trueLabel: {
      type: String,
      default: () => '',
    },
    falseLabel: {
      type: String,
      default: () => '',
    },
    editable: {
      type: Boolean,
      default: () => false,
    },
    required: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isEvalItemExists() {
      return Boolean(this.result) && Boolean(this.result.riskFactors)
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
    },
    datachange() {
      if (this.result.editFlag !== 'C') {
        this.result.editFlag = 'U'
        this.result.chgUserId = this.$store.getters.user.userId
      }
      this.$emit('datachange', this.result)
    },
    checkboxRule(val) {
      return !this.required || (val && val.length > 0) || ''
    }
  }
};
</script>
<style lang="sass">
.container-area
 display: flex
 align-items: center
.header-area
  width: 110px
  flex-shrink: 0
  color: #0072c6 !important
  font-weight: 600
.value-area
  flex-shrink: 1
  flex-grow: 1
</style>