var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isEvalItemExists
    ? _c(
        "q-td",
        {
          staticClass: "text-center th-td-style td-style",
          attrs: { rowspan: 1, colspan: _vm.colspan },
        },
        [
          _c("div", { staticClass: "container-area" }, [
            _c("div", { staticClass: "header-area" }, [
              _vm._v(" " + _vm._s(_vm.result.riskFactors) + " "),
              _vm.required
                ? _c(
                    "i",
                    {
                      staticClass:
                        "material-icons labelfrontIcon text-requiredColor",
                    },
                    [_vm._v("check")]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "value-area" },
              [
                _vm.type === "number"
                  ? [
                      _c("c-number-column", {
                        staticClass: "tableHeaderText",
                        attrs: {
                          required: _vm.required,
                          editable: _vm.editable,
                          col: { name: "riskFactorsResult" },
                          props: { row: _vm.result },
                          dense: "",
                          type: "number",
                        },
                        on: { datachange: _vm.datachange },
                        model: {
                          value: _vm.result.riskFactorsResult,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "riskFactorsResult", $$v)
                          },
                          expression: "result.riskFactorsResult",
                        },
                      }),
                    ]
                  : _vm.type === "radio"
                  ? [
                      _c("c-radio", {
                        attrs: {
                          required: _vm.required,
                          editable: _vm.editable,
                          codeGroupCd: _vm.codeGroupCd,
                          itemText: "codeName",
                          itemValue: "code",
                          label: "",
                        },
                        on: { datachange: _vm.datachange },
                        model: {
                          value: _vm.result.riskFactorsResult,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "riskFactorsResult", $$v)
                          },
                          expression: "result.riskFactorsResult",
                        },
                      }),
                    ]
                  : _vm.type === "check"
                  ? [
                      _c("q-checkbox", {
                        staticClass: "tableCheckBox",
                        attrs: {
                          required: _vm.required,
                          disable: !_vm.editable,
                          "true-value": _vm.trueValue ? _vm.trueValue : "Y",
                          "false-value": _vm.falseValue ? _vm.falseValue : "N",
                          rules: [_vm.checkboxRule],
                          dense: "",
                          color: "orange-custom",
                        },
                        on: { datachange: _vm.datachange },
                        model: {
                          value: _vm.result.riskFactorsResult,
                          callback: function ($$v) {
                            _vm.$set(_vm.result, "riskFactorsResult", $$v)
                          },
                          expression: "result.riskFactorsResult",
                        },
                      }),
                    ]
                  : _vm.type === "text"
                  ? [
                      _c(
                        "span",
                        { staticClass: "text-left" },
                        [
                          _c("c-text-column", {
                            attrs: {
                              required: _vm.required,
                              editable: _vm.editable,
                              col: { name: "riskFactorsResult", align: "left" },
                              props: { row: _vm.result },
                            },
                            on: { datachange: _vm.datachange },
                          }),
                        ],
                        1
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }